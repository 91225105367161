jQuery(document).ready(function ($) {

    /* ==========================================================================
    == NAV MOBILE ==
    ========================================================================== */

    $('.tools__item--menu').click(function(){
        if ($('.tools__icon', this).hasClass('icon-nav')) {
            $('.tools__icon', this).removeClass("icon-nav").addClass("icon-close");
            $('.menu').addClass('menu-active');
        } else {
            $('.tools__icon', this).removeClass("icon-close").addClass("icon-nav");
            $('.menu').removeClass('menu-active');
        }
        return false;
    });


    /* ==========================================================================
    == SLIDER HOME ==
    ========================================================================== */

    $('.slider__txt').slick({
        autoplay      : true,
        autoplaySpeed : 6000,
        arrows        : false,
        dots          : true,
        fade          : true,
        pauseOnHover  : false,
        pauseOnFocus  : false,
        swipe         : false,
        touchMove     : false,
        speed         : 1500,
        asNavFor      : '.slider__img'
    });

    $('.slider__img').slick({
        arrows        : false,
        fade          : true,
        pauseOnHover  : false,
        pauseOnFocus  : false,
        swipe         : false,
        touchMove     : false,
        speed         : 1500,
        asNavFor      : '.slider__txt'
    });



    /* ==========================================================================
    == TEMOIGNAGES HOME ==
    ========================================================================== */

    $('.tem').slick({
        autoplay       : true,
        autoplaySpeed  : 8000,
        infinite       : true,
        slidesToShow   : 1,
        slidesToScroll : 1,
        arrows         : false,
        dots           : true,
        mobileFirst    : true,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    });


    /* ==========================================================================
    == ACCORDEONS ==
    ========================================================================== */

    (function ($) {
        $('.accordion__panel').hide();
        $('.accordion__head').click(function () {
            if ($(this).hasClass('accordion__head--active')) {
                $(this).removeClass('accordion__head--active');
                $(this).next().slideUp();
            } else {
                $(this).addClass('accordion__head--active');
                $(this).next().slideDown();
            }
            return false;
        });
    })(jQuery);


});